<template>
  <navigation index="purchaseMoney" ref="navigation">
    <div class="topnavigation">
      <el-button
        style="margin-left: 10px"
        type="primary"
        @click="
          OpenStorePricing({
            Name: '',
            Day: 0,
            Amount: 0,
            AccountMaxCount: 10,
            TaxRate: 0,
            GiveDay: 0,
          })
        "
        >新增</el-button
      >
    </div>

    <el-table
      border
      :data="StorePricingDatas"
      style="width: 100%"
      :height="TableHeight + 'px'"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column label="名称" width="180" prop="Name"> </el-table-column>
      <el-table-column label="时长" width="180" prop="Day"></el-table-column>
      <el-table-column label="金额" width="180" prop="Amount"></el-table-column>
      <el-table-column label="税率" width="180" prop="TaxRate">
        <template #default="scope"> {{ scope.row.TaxRate }}% </template>
      </el-table-column>
      <el-table-column label="赠送" width="180" prop="GiveDay">
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="300">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="OpenStorePricing(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            v-if="!scope.row.IsDisabled"
            @click="DisabledStorePricing(scope.row)"
            type="danger"
            >禁用</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.IsDisabled"
            @click="CancelDisabledStorePricing(scope.row)"
            type="success"
            >解禁</el-button
          >
          <el-button size="small" @click="DeleteStorePricing(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-form label-width="120px">
      <el-form-item label="允许试用">
        <el-switch v-model="Settings.TryOut" />
      </el-form-item>
      <el-form-item label="试用天数">
        <el-input-number v-model="Settings.Qty" />
      </el-form-item>
      <el-form-item>
        <el-button @click="InsertTryOutSettings">保存</el-button>
      </el-form-item>
    </el-form>

    <!-- 编辑定价 -->
    <el-dialog
      v-model="StorePricingWindow"
      title="编辑定价"
      width="800px"
      :draggable="true"
      :show-close="false"
    >
      <el-form
        ref="StorePricingRef"
        style="margin-top: 10px"
        label-width="120px"
        :model="StorePricingOpt"
      >
        <el-form-item label="名称" prop="Name">
          <el-input v-model="StorePricingOpt.Name"></el-input>
        </el-form-item>
        <el-form-item
          label="天数"
          prop="Day"
          :rules="[
            { required: true, message: '请输入天数' },
            { type: 'number', message: '请输入数字' },
          ]"
        >
          <el-input-number v-model="StorePricingOpt.Day"></el-input-number>
        </el-form-item>
        <el-form-item
          label="金额"
          prop="Amount"
          :rules="[
            { required: true, message: '请输入金额' },
            { type: 'number', message: '请输入数字' },
          ]"
        >
          <el-input-number v-model="StorePricingOpt.Amount"></el-input-number>
        </el-form-item>
        <el-form-item
          label="账号数量"
          prop="AccountMaxCount"
          :rules="[
            { required: true, message: '请输入账号数量' },
            { type: 'number', message: '请输入数字' },
          ]"
        >
          <el-input-number
            v-model="StorePricingOpt.AccountMaxCount"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="税率"
          prop="TaxRate"
          :rules="[
            { required: true, message: '请输入税率' },
            { type: 'number', message: '请输入数字' },
          ]"
        >
          <el-input-number v-model="StorePricingOpt.TaxRate"></el-input-number>
        </el-form-item>
        <el-form-item
          label="赠送天数"
          prop="GiveDay"
          :rules="[
            { required: true, message: '请输入赠送天数' },
            { type: 'number', message: '请输入赠送天数' },
          ]"
        >
          <el-input-number :min="0" v-model="StorePricingOpt.GiveDay"></el-input-number>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="StorePricingWindow = !StorePricingWindow"
            >关闭</el-button
          >
          <el-button @click="InsertStorePricing(StorePricingOpt)"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import { ElMessageBox, ElMessage } from "element-plus";
import navigation from "../../../components/Modules/AdminNavigation.vue";
import "../../../assets/css/commons.css";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      TableHeight: document.documentElement.clientHeight - 120,
      ContentHeight: document.documentElement.clientHeight - 200,
      AmountSettings: {
        Day30: 0,
        Day90: 0,
        Day180: 0,
        FreeDay30: 0,
        FreeDay90: 0,
        FreeDay180: 0,

        PermitCustomDay: false, //允许自定义时间
        DayPrive: 1, //每日价格

        Probation: false, //启用试用
        ProbationDay: 0, //试用天数
      },
      StorePricingDatas: [],
      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      StorePricingWindow: false,
      StorePricingOpt: {},
      Settings: {
        Qty: 3,
        TryOut: false,
      },
    };
  },
  name: "App",
  mounted() {
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 520;
    this.ContentHeight = document.documentElement.clientHeight - 200;

    this.GetStorePricing();
    this.GetTryOutSettings();
  },
  components: { navigation },
  methods: {
    //保存试用配置
    GetTryOutSettings(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/GetTryOutSettings",
        "get",
        item,
        (result) => {
          this.Settings = result.Data;
        }
      );
    },
    //保存试用配置
    InsertTryOutSettings(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/InsertTryOutSettings",
        "post",
        this.Settings,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetTryOutSettings();
          }
        }
      );
    },
    //删除
    DeleteStorePricing(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/DeleteStorePricing",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetStorePricing();
          }
        }
      );
    },
    //解禁
    CancelDisabledStorePricing(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/CancelDisabledStorePricing",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetStorePricing();
          }
        }
      );
    },
    //禁用
    DisabledStorePricing(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/DisabledStorePricing",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetStorePricing();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //插入定价信息
    InsertStorePricing(item) {
      this.$refs.StorePricingRef.validate((valid) => {
        if (valid) {
          axios.apiMethod(
            "/admins/StoreMaintain/InsertStorePricing",
            "post",
            item,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetStorePricing();
                this.StorePricingWindow = false;
              } else {
                ElMessage({
                  dangerouslyUseHTMLString: true,
                  message: result.Data.Msg,
                });
              }
            }
          );
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    //打开定价编辑页面
    OpenStorePricing(item) {
      this.StorePricingOpt = item;
      this.StorePricingWindow = true;
    },
    //查询定价列表
    GetStorePricing() {
      axios.apiMethod(
        "/admins/StoreMaintain/GetStorePricing",
        "post",
        this.padding,
        (result) => {
          this.StorePricingDatas = result.Data.Datas;

          this.padding.Page = result.Data.Page;
          this.padding.Size = result.Data.Size;
          this.padding.Count = result.Data.Count;
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
